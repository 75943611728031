import { useNavigate } from 'react-router-dom'
import { ChatStore } from '../chats/chatStore'
import { VocabLessonStore } from '../vocabLessons/vocabLessonStore'
import { useSnapshot } from 'valtio'
import { useLazyRef } from '../../shared/lib/hooks'
import { RateLessonStore } from '../rateLesson/rateLessonStore'
import { cn, nilMap } from '../../shared/lib/utils'
import { urls } from '../../shared/urls'
import { AppMessageKeys } from '../../shared/translations/messages'
import Rive, { Fit, Layout } from '@rive-app/react-canvas'
import { motion, AnimatePresence } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { RateLesson } from '../rateLesson/rateLesson'
import { BottomButtons } from '../../shared/ui/bottomButtons'

const StarIcon = () => {
  return (
    <motion.img
      src="/images/star.png"
      className="size-72"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    />
  )
}

export const LessonIsCompleted = ({
  store,
}: {
  store: ChatStore | VocabLessonStore
}) => {
  const navigate = useNavigate()
  // const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const rateStore = useLazyRef(() => new RateLessonStore()).current
  const rateState = useSnapshot(rateStore.state)

  const suitableLevel = rateState.suitableLevel
  const experienceRating = rateState.experienceRating
  const addFeedback = rateState.additionalFeedback
  const isDemo = state.isDemo

  const handleSubmit = async () => {
    void store.rateChat(
      nilMap(suitableLevel, (x) => x + 1),
      nilMap(experienceRating, (x) => x + 1),
      addFeedback,
    )

    if (isDemo) {
      navigate(urls.onboarding('generation'))
    } else {
      await store.goToLessonStat()
    }
  }
  const starsTextIds: Record<number, AppMessageKeys> = {
    1: 'finish.oneStar',
    2: 'finish.twoStars',
    3: 'finish.threeStars',
  }
  return (
    <div className="fixed left-0 top-0 z-20 flex h-[100dvh] w-full flex-col items-center bg-blue6 backdrop-blur-20">
      <Rive
        className="pointer-events-none absolute z-40 size-full"
        src="/animations/confetti.riv"
        layout={
          new Layout({
            fit: Fit.Cover,
          })
        }
      />
      <AnimatePresence>
        {!rateState.experienceRating && (
          <motion.div
            id="lesson_start"
            className={cn('relative h-[calc(100dvh-120px)]')}
            initial={{ opacity: 0, height: 'auto' }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: '0' }}
            transition={{
              opacity: { duration: 0.15 },
              height: { duration: 0.3 },
            }}
          >
            <div className="mx-36 mt-100 text-center text-48 font-extrabold text-black">
              <FormattedMessage id="Lesson completed!" />
              <div className="mx-auto mt-32 flex justify-center">
                {!state.loading &&
                  [...Array(isDemo ? 3 : state.lessonRate).keys()].map((i) => (
                    <StarIcon key={`star-${i}`} />
                  ))}
              </div>
              {!state.loading && (
                <p className="mt-24 text-14 font-bold text-gray4">
                  <FormattedMessage
                    id={
                      isDemo
                        ? 'finish.demo'
                        : state.lessonRate === null
                        ? 'EmptyId'
                        : starsTextIds[state.lessonRate]
                    }
                  />
                </p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {' '}
        <RateLesson id="lesson_exp" store={rateStore} />
      </AnimatePresence>
      <BottomButtons
        firstButtonProps={{
          handler: () => {
            void store.newChat(state.programTag, true, state.lessonType)
          },
          textId: 'Repeat the lesson',
          bg: 'transparent',
          visible: !isDemo,
        }}
        secondButtonProps={{
          handler: () => {
            void (async () => {
              await handleSubmit()
            })()
          },
          textId: 'Finish the lesson',
          bg: 'black-gradient',
          visible: true,
        }}
      />
    </div>
  )
}
