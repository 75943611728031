/* eslint-disable boundaries/no-ignored */
import AsteriskIcon from './asterisk.svg?react'
import CheckGreenIcon from './check-green.svg?react'
import TranslateIcon from './translate.svg?react'
import DeleteIcon from './delete.svg?react'
import DoneIcon from './done.svg?react'
import PinIcon from './pin.svg?react'
import WarningIcon from './warning.svg?react'
import UnpinIcon from './unpin.svg?react'
import MicIcon from './mic.svg?react'
import ExitIcon from './exit.svg?react'
import CameraOnIcon from './camera-on.svg?react'
import CameraOffIcon from './camera-off.svg?react'
import ArrowLeftIcon from './arrow-left.svg?react'
import ArrowRightIcon from './arrow-right.svg?react'
import UserIcon from './user.svg?react'
import HistoryIcon from './history.svg?react'
import KeyboardIcon from './keyboard.svg?react'
import HelpIcon from './help.svg?react'
import SlowIcon from './slow.svg?react'
import SubtitleIcon from './subtitle.svg?react'
import SubtitleOffIcon from './subtitle-off.svg?react'
import PlayIcon from './play.svg?react'
import PlayMessageIcon from './play-message.svg?react'
import CheckIcon from './check.svg?react'
import LockIcon from './lock.svg?react'
import DictionaryIcon from './dictionary.svg?react'
import UnfoldIcon from './unfold.svg?react'
import ThumbupIcon from './thumbup.svg?react'
import CloseIcon from './close.svg?react'
import LeftIcon from './left.svg?react'
import ProfileIcon from './profile.svg?react'
import LearningIcon from './learning.svg?react'
import GrammarIcon from './grammar.svg?react'
import WordsIcon from './words.svg?react'
import SendIcon from './send.svg?react'
import ArrowUpIcon from './arrow-up.svg?react'
import ArrowDownIcon from './arrow-down.svg?react'
import MenuIcon from './menu.svg?react'
import VoiceIcon from './voice.svg?react'
import SupportIcon from './support.svg?react'
import BulbIcon from './bulb.svg?react'
import ZoomIcon from './zoom.svg?react'
import KeyIcon from './key.svg?react'
import UnmuteIcon from './unmute.svg?react'
import MuteIcon from './mute.svg?react'
import TrendUpIcon from './trendUp.svg?react'
import Profile2Icon from './profile2.svg?react'
import React from 'react'

const icons = {
  translate: TranslateIcon,
  unmute: UnmuteIcon,
  done: DoneIcon,
  mute: MuteIcon,
  pin: PinIcon,
  delete: DeleteIcon,
  warning: WarningIcon,
  unpin: UnpinIcon,
  exit: ExitIcon,
  mic: MicIcon,
  cameraOn: CameraOnIcon,
  cameraOff: CameraOffIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  user: UserIcon,
  history: HistoryIcon,
  keyboard: KeyboardIcon,
  help: HelpIcon,
  slow: SlowIcon,
  subtitle: SubtitleIcon,
  subtitleOff: SubtitleOffIcon,
  play: PlayIcon,
  playMessage: PlayMessageIcon,
  check: CheckIcon,
  lock: LockIcon,
  dictionary: DictionaryIcon,
  unfold: UnfoldIcon,
  thumbup: ThumbupIcon,
  close: CloseIcon,
  left: LeftIcon,
  profile: ProfileIcon,
  profile2: Profile2Icon,
  learning: LearningIcon,
  grammar: GrammarIcon,
  words: WordsIcon,
  send: SendIcon,
  arrowUp: ArrowUpIcon,
  arrowDown: ArrowDownIcon,
  menu: MenuIcon,
  voice: VoiceIcon,
  support: SupportIcon,
  bulb: BulbIcon,
  zoom: ZoomIcon,
  key: KeyIcon,
  trendUp: TrendUpIcon,
  asterisk: AsteriskIcon,
  checkGreen: CheckGreenIcon,
}

export type IconName = keyof typeof icons

interface IconProps {
  iconName: IconName
  className?: string
  onClick?: (e: React.MouseEvent) => void
  size?: keyof typeof sizes
}

const sizes = {
  xs: 12,
  little: 16,
  xsm: 18,
  sm: 20,
  small: 24,
  large: 32,
}

export const Icon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { iconName, className, size, onClick, ...rest } = props
  const SvgIcon = icons[iconName]
  const finalSize = size ? sizes[size] : 24
  return (
    <SvgIcon
      ref={ref}
      width={finalSize}
      height={finalSize}
      onClick={onClick}
      className={className}
      {...rest}
    />
  )
})
Icon.displayName = 'Icon'
