import { ChatApi } from '../../shared/api/chatApi.ts'
import { none, taggedUnion, type } from '../../shared/lib/taggedUnion.ts'
import { addDays } from 'date-fns'
import { AuthStore } from '../auth/authStore.ts'

const SoonExpiredDaysCount = 5

export const SubscriptionState = taggedUnion({
  inactive: none,
  active: none,
  soonExpired: type<{ date: Date }>(),
})
export type SubscriptionState = typeof SubscriptionState.type
export class SubscriptionService {
  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
  ) {}

  async getActiveSubscription() {
    const subscriptions = await this.chatApi.subscriptions()
    return subscriptions.find((x) => x.active)
  }

  async getSubscriptionState(): Promise<SubscriptionState> {
    if (this.authStore.state.isAnon) {
      return SubscriptionState.active()
    }
    const activeSubscription = await this.getActiveSubscription()
    if (activeSubscription) {
      return !activeSubscription.recurrent &&
        activeSubscription.days_estimated <= SoonExpiredDaysCount
        ? SubscriptionState.soonExpired({
            date: addDays(
              activeSubscription.subscription_date,
              activeSubscription.days,
            ),
          })
        : SubscriptionState.active()
    }
    return SubscriptionState.inactive()
  }
}
