import { useIsMobile, useLazyRef } from '../../shared/lib/hooks.ts'
import {
  AddWordDrawer,
  ChatStoreContext,
  ChatView,
  HeaderHintView,
  HintView,
  messageStageElementId,
  useStores,
  VocabularyColumn,
  VocabularyDrawer,
} from '../../features/chats/chatView.tsx'
import { useAppModule } from '../../features/appContext.ts'
import { useSnapshot } from 'valtio/react'
import {
  cn,
  isKeyInObject,
  nilMap,
  useUnmount,
} from '../../shared/lib/utils.ts'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { classed } from '@tw-classed/react'
import {
  ChooseLanguageDialog,
  DemoComplete,
} from '../../features/chats/demoChat.tsx'
import { StageProgress } from '../../shared/ui/StageLine/StageProgress.tsx'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import {
  AppHeader,
  AppHeaderNavigationButton,
} from '../../features/appHeader/appHeader.tsx'
import { toUppercase } from '../../shared/lib/stringUtils.ts'
import { LessonType } from '../../shared/api/chatApi.ts'

export function FullChat(props: { tag: string }) {
  const location = useLocation()
  const state = location.state as {
    recreateChat: boolean
    lessonType: LessonType
  } | null
  const recreateChat =
    state !== null && isKeyInObject('recreateChat', state)
      ? state.recreateChat
      : false

  const lessonType =
    state !== null && isKeyInObject('lessonType', state)
      ? state.lessonType
      : 'lesson'

  const appModule = useAppModule()
  const isMobile = useIsMobile()

  const chatStoresRef = useLazyRef(() => {
    const chatStore = appModule.chatStore()
    const recordStore = appModule.chatAudioRecordStore(chatStore)
    void chatStore.newChat(props.tag, recreateChat, lessonType)
    return {
      chatStore,
      recordStore,
    }
  })

  const chatState = useSnapshot(chatStoresRef.current.chatStore.state)
  const authState = useSnapshot(appModule.authStore.state)

  useUnmount(() => {
    chatStoresRef.current.recordStore.stop()
    appModule.speechService.dispose()
  })

  const demoComplete = Boolean(false)

  useEffect(() => {
    appModule.appStore.setHideMenu(demoComplete)
  }, [appModule.appStore, demoComplete])

  const navigate = useNavigate()
  useEffect(() => {
    if (
      chatStoresRef.current.chatStore.state.isDemo &&
      chatState.showChatBlock
    ) {
      navigate(urls.demo(), { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatState.showChatBlock])

  useEffect(() => {
    if (chatState.url) {
      navigate(chatState.url, {
        replace: true,
      })
    }
  }, [chatState.programTag, chatState.url, chatStoresRef, navigate])
  const progressBlock = isMobile ? (
    <StageProgressBlockMobile />
  ) : (
    <StageProgressBlock complete={demoComplete} />
  )

  return (
    <ChatStoreContext.Provider value={chatStoresRef.current}>
      <div
        className={cn(
          'flex h-dvh w-full flex-col',
          demoComplete && 'overflow-y-auto bg-violetd sm:pr-[10%]',
        )}
      >
        {progressBlock}
        {demoComplete ? (
          <DemoComplete />
        ) : (
          <FullChatContainer data-testid="chat-page">
            {chatState.loading == 'chat' || !authState.user ? (
              <Spinner className="m-auto" size="large" />
            ) : (
              <>
                <ChatView />
                {chatState.lessonType === 'lesson' && <VocabularyColumn />}
                {chatState.isDemo && <ChooseLanguageDialog />}
              </>
            )}
          </FullChatContainer>
        )}
        <AddWordDrawer />
        {isMobile && <HintView />}
      </div>
    </ChatStoreContext.Provider>
  )
}

function StageProgressBlockMobile() {
  const store = useStores().chatStore
  const chatState = useSnapshot(store.state)
  const isLessonCompleted = chatState.lessonCompleted
  return (
    <div className="fixed left-0 top-0 z-20 flex h-100 w-full items-center bg-demo-mobile-header px-16 backdrop-blur-4">
      <div className="flex items-center">
        {!chatState.isDemo && !isLessonCompleted && (
          <AppHeaderNavigationButton />
        )}
      </div>
      <div className="ml-16 flex-1 text-start text-18 font-bold">
        {chatState.isDemo ? (
          <FormattedMessage id="Demo lesson" />
        ) : (
          !!chatState.program && chatState.program.lesson_subject
        )}
      </div>
      {chatState.lessonType === 'lesson' && (
        <div className={cn(chatState.currentHint === 'allWords' && 'z-40')}>
          <VocabularyDrawer />
        </div>
      )}
      <HeaderHintView />
    </div>
  )
}

function StageProgressBlock(props: { complete: boolean; className?: string }) {
  const store = useStores().chatStore
  const chatState = useSnapshot(store.state)
  const appStore = useAppModule().appStore
  const appState = useSnapshot(appStore.state)
  const language = appState.language
  const stages = nilMap(chatState.stages, (x) => x[toUppercase(language)])
  return (
    <AppHeader backButton={!chatState.isDemo} className={props.className}>
      {stages && (
        <div className="mx-auto w-full max-w-800 animate-in fade-in">
          <div
            className={cn(
              'relative mb-16 flex items-center gap-12 text-center text-14 font-bold sm:mb-0 sm:text-left sm:text-20',
              'text-white',
            )}
          >
            {chatState.isDemo ? (
              <FormattedMessage id="Demo lesson" />
            ) : (
              chatState.program?.topic
            )}
          </div>
          <StageProgress
            disabled={props.complete}
            className="mx-auto max-w-800 pr-12 mxs:hidden sm:pr-0"
            stages={stages}
            currentStage={chatState.currentStage}
            onStageSelected={(stage) => {
              document
                .getElementById(messageStageElementId(stage))
                ?.scrollIntoView({ behavior: 'smooth' })
            }}
          />
        </div>
      )}
      {!props.complete && <div className="hidden h-12 w-400 sm:block"></div>}
    </AppHeader>
  )
}

export const FullChatContainer = classed.div(
  'flex w-full flex-1 justify-between gap-24 overflow-hidden sm:rounded-tl-8 sm:bg-white sm:px-36 sm:pt-36',
)
