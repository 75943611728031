import { useLazyRef } from '../shared/lib/hooks.ts'
import { useAppModule } from '../features/appContext.ts'
import { SubscriptionView } from '../features/onboarding/subscriptionView.tsx'
import { BuyStoreContext, Country } from '../features/onboarding/buyStore.ts'
import { Toasts } from '../features/toasts/toasts.tsx'
import { useMount } from '../shared/lib/utils.ts'
import { IntlProvider } from 'react-intl'
import { messages } from '../shared/translations/messages.ts'

export function SubscriptionsPage(props: { country: Country }) {
  const appModule = useAppModule()
  const buyStore = useLazyRef(() => appModule.buyStore(props.country)).current
  const authStore = useAppModule().authStore

  useMount(() => {
    authStore.trackOnbMetrika('onboarding_passed')
    authStore.trackOnbMetrika('subscriptions')
    authStore.trackMetrika('demo_lesson_start')
    authStore.trackMetrika('demo2_subscription_visit')
    authStore.trackEvent('subscribtion_page_load')
    void authStore.saveMarkData()
  })

  return (
    <IntlProvider locale="ru" messages={messages.ru}>
      <BuyStoreContext.Provider value={buyStore}>
        <SubscriptionView />
        <Toasts />
      </BuyStoreContext.Provider>
    </IntlProvider>
  )
}
