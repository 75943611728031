export const MOCK_VOCAB_LESSONS = [
  {
    img: '/images/program/lessons/vocab.png',
    lessonText: 'Match',
    lessonTag: 'Vocab practice MW',
  },
  {
    img: '/images/program/lessons/listening.png',
    lessonText: 'Listen and match',
    lessonTag: 'Vocab practice MA',
  },
  {
    img: '/images/program/lessons/grammar.png',
    lessonText: 'Multiple choice',
    lessonTag: 'Vocab practice MC',
  },
  {
    img: '/images/program/lessons/human.png',
    lessonText: 'Complex lesson',
    lessonTag: 'Vocab practice CL',
  },
]
