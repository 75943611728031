import React from 'react'
import { useProfileStore } from './ProfileStore.ts'
import { useSnapshot } from 'valtio'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { FormattedMessage } from 'react-intl'
import { planInfos } from '../onboarding/subscriptionView.tsx'

export const ProfileSubscription = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const navigate = useNavigate()
  const subscription = state.subscription

  return (
    <div className="flex flex-col gap-16">
      <ProfileTitle title={<FormattedMessage id="user.subscription" />} />
      <ProfileMenuBlock>
        <ProfileButton
          withArrow
          onClick={() => {
            navigate(urls.subscriptions())
          }}
          title={<FormattedMessage id="user.changeSubscription" />}
          disabled={true}
          subtitle={
            state.subscription?.recurrent ? (
              <FormattedMessage
                id="recurrent"
                values={{
                  count: planInfos[state.subscription.plan].month,
                }}
              />
            ) : (
              ''
            )
          }
        />
      </ProfileMenuBlock>
      {subscription?.recurrent && (
        <ProfileMenuBlock>
          <ProfileButton
            alertTitle
            onClick={() => {
              store.setShowCancelSubscription(true)
            }}
            title={<FormattedMessage id="user.cancelSubscription" />}
          />
        </ProfileMenuBlock>
      )}

      <CancelSubscriptionDrawer />
    </div>
  )
}

function CancelSubscriptionDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  return (
    <ProfileDrawer
      title={<FormattedMessage id="user.cancelSubscription?" />}
      open={state.showCancelSubscription}
    >
      <div className="flex flex-col gap-16">
        <div>
          <FormattedMessage id="user.areYouSureCancel" />
        </div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            void store.unsubscribe()
          }}
        >
          <FormattedMessage id="yes" />
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.setShowCancelSubscription(false)
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </ProfileDrawer>
  )
}
