import { FormattedMessage } from 'react-intl'
import { AppMessageKeys } from '../translations/messages'
import { Button } from './button/button'
import { Icon } from './icon/icon'

interface IButtonProp {
  handler: () => void
  textId: AppMessageKeys
  bg: 'transparent' | 'black-gradient' | 'blue-gradient'
  visible: boolean
}

interface IBottomButtons {
  firstButtonProps: IButtonProp
  secondButtonProps: IButtonProp
}

export const BottomButtons = ({
  firstButtonProps,
  secondButtonProps,
}: IBottomButtons) => {
  return (
    <div className="absolute inset-x-16 bottom-16 z-30 flex flex-col gap-12">
      {firstButtonProps.visible && (
        <Button
          rounded="full"
          bg={firstButtonProps.bg}
          size="extralarge"
          onClick={(e) => {
            e.stopPropagation()
            firstButtonProps.handler()
          }}
        >
          <FormattedMessage id={firstButtonProps.textId} />
        </Button>
      )}
      {secondButtonProps.visible && (
        <Button
          size="extralarge"
          rounded="full"
          bg={secondButtonProps.bg}
          className="flex gap-8"
          onClick={(e) => {
            e.stopPropagation()
            secondButtonProps.handler()
          }}
        >
          <FormattedMessage id={secondButtonProps.textId} />
          <Icon iconName="arrowRight" />
        </Button>
      )}
    </div>
  )
}
