import { AuthStore } from './auth/authStore.ts'
import { ChatApi } from '../shared/api/chatApi.ts'
import { ToastStore } from './toasts/toastStore.ts'
import { ChatStore } from './chats/chatStore.ts'
import { SpeechService } from './speech/speechService.ts'
import { ChatListStore } from './chats/chatListStore.ts'
import { ChatAudioRecordStore } from './chats/chatAudioRecordStore.ts'
import { AppLocalStorage } from '../shared/lib/appLocalStorage.ts'
import { WhatIsNewStore } from './whatisnew/whatIsNewStore.ts'
import { RateMessageStore } from './rateMessage/RateMessageStore.ts'
import { SubscriptionService } from './subscriptions/subscriptionService.ts'
import { ProgramStore } from './programs/programStore.ts'
import { HideTooltipManager } from '../shared/lib/hideTooltipManager.ts'
import { BuyStore, Country } from './onboarding/buyStore.ts'
import { AppStore } from './whatisnew/appStore.ts'
import { AuthApi } from '../shared/api/authApi.ts'
import { HttpClient } from '../shared/api/httpClient.ts'
import { StreamService } from './speech/audio/streamService.ts'
import { EdenaiApi } from '../shared/api/edenaiApi.ts'
import { AppSessionStorage } from '../shared/lib/appSessionStorage.ts'
import { AnswerStatusStore } from './exercises/answerStatusDrawer.tsx'
import { ProgressApi } from '../shared/api/progressApi.ts'
import { ProgressStore } from './progress/progressStore.ts'
import { ProfileStore } from './profile/ProfileStore.ts'
import { OnboardingData } from './onboarding/onboardingData.ts'
import { OnboardingStore } from './onboarding/OnboardingStore.ts'
import { VocabLessonStore } from './vocabLessons/vocabLessonStore.ts'
import { PracticeApi } from '../shared/api/practiceApi.ts'

export class MainModule {
  hideTooltipManager: HideTooltipManager
  appStore: AppStore
  authStore: AuthStore
  speechService: SpeechService
  answerStatusStore: AnswerStatusStore = new AnswerStatusStore()

  constructor(
    public httpClient: HttpClient,
    public authApi: AuthApi,
    public toastStore: ToastStore,
    public chatsApi: ChatApi,
    public progressApi: ProgressApi,
    public practiceApi: PracticeApi,
    public appStorage: AppLocalStorage,
    public appSessionStorage: AppSessionStorage,
    public progressStore: ProgressStore,
  ) {
    this.hideTooltipManager = new HideTooltipManager(this.appStorage)
    this.appStore = new AppStore(this.appStorage)
    this.authStore = new AuthStore(
      httpClient,
      authApi,
      appStorage,
      this.appStore,
      this.appSessionStorage,
    )
    const streamService = new StreamService(this.authStore)
    const edanaiApi = new EdenaiApi(httpClient)
    this.speechService = new SpeechService(streamService, edanaiApi, toastStore)
  }

  get streamService() {
    return this.speechService.streamService
  }

  buyStore(country: Country) {
    const store = new BuyStore(
      this.authStore,
      this.appStore,
      this.chatsApi,
      this.toastStore,
    )
    store.setCountry(country)
    return store
  }

  onboardingStore(onboardingData: OnboardingData) {
    return new OnboardingStore(
      this.chatsApi,
      this.authStore,
      this.subscriptionService(),
      onboardingData,
      this.appSessionStorage,
      'hello',
    )
  }

  profileStore(onboardingData: OnboardingData) {
    return new ProfileStore(
      this.chatsApi,
      this.authStore,
      this.subscriptionService(),
      this.onboardingStore(onboardingData),
    )
  }
  chatStore() {
    return new ChatStore(
      this.chatsApi,
      this.authStore,
      this.speechService,
      this.appStore,
      this.progressApi,
    )
  }

  vocabLessonStore() {
    return new VocabLessonStore(
      this.chatsApi,
      this.authStore,
      this.speechService,
      this.appStore,
      this.progressApi,
    )
  }

  programStore(registration: boolean) {
    return new ProgramStore(
      registration,
      this.chatsApi,
      this.subscriptionService(),
      this.appStorage,
      this.authStore,
    )
  }

  subscriptionService() {
    return new SubscriptionService(this.chatsApi, this.authStore)
  }

  rateMessageStore() {
    return new RateMessageStore(this.chatsApi)
  }
  chatAudioRecordStore(chatStore: ChatStore) {
    return new ChatAudioRecordStore(
      this.speechService,
      this.toastStore,
      this.chatsApi,
      chatStore,
      this.hideTooltipManager,
      this.authStore,
    )
  }
  chatListStore() {
    return new ChatListStore(this.chatsApi, this.authStore)
  }

  createWhatIsNewStore() {
    return new WhatIsNewStore(this.chatsApi, this.appStorage)
  }
}
