import { PickPublic } from '../lib/utils.ts'
import { HttpClient } from './httpClient.ts'

export type PracticeApi = PickPublic<AppPracticeApi>
export class AppPracticeApi {
  constructor(private httpClient: HttpClient) {}

  async getPracticePrograms() {
    return await this.httpClient.get(`/api/user_practice/`)
  }
}
