import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { cn } from '../../shared/lib/utils.ts'
import { urls } from '../../shared/urls.ts'
import { MOCK_VOCAB_LESSONS } from '../../shared/data/practice.ts'
import { AppMessageKeys } from 'src/shared/translations/messages'

export interface VocabProgressElemType {
  textColor: string
  bgColor: string
}

export interface VocabProgressTagType {
  bgColor: string
  id: AppMessageKeys
}

// export const VOCAB_PROGRESS_BLOCKS: VocabProgressElemType[] = [
//   { textColor: 'text-blue2', bgColor: 'bg-blue' },
//   { textColor: 'text-orange5', bgColor: 'bg-orange4' },
//   { textColor: 'text-green2', bgColor: 'bg-green3' },
// ]

// export const VOCAB_PROGRESS_TAGS: VocabProgressTagType[] = [
//   { bgColor: 'bg-blue2', id: 'practice.new' },
//   { bgColor: 'bg-orange5', id: 'practice.weak' },
//   { bgColor: 'bg-green2', id: 'practice.strong' },
// ]

// const ProgressElement = ({
//   textColor,
//   bgColor,
//   value,
// }: VocabProgressElemType & { value: number }) => {
//   return (
//     <div className={cn('relative h-full w-1/3', bgColor)}>
//       <p
//         className={cn(
//           'absolute bottom-4 right-12 text-24 font-extrabold',
//           textColor,
//         )}
//       >
//         {value}
//       </p>
//     </div>
//   )
// }

// const ProgressElemTag = ({ bgColor, id }: VocabProgressTagType) => {
//   return (
//     <div className="flex items-center gap-4 font-nunito-7-condensed text-14 font-bold text-gray4">
//       <div className={cn('size-8 rounded-full', bgColor)} />
//       <FormattedMessage id={id} />
//     </div>
//   )
// }

export const VocabLesson = ({
  img,
  lessonText,
  lessonTag,
}: {
  img: string
  lessonText: string
  lessonTag: string
}) => {
  const navigate = useNavigate()
  return (
    <div
      className="flex h-fit w-full cursor-pointer gap-12 rounded-16 bg-gray-light p-16"
      onClick={() => {
        navigate(urls.vocabLesson(lessonTag), {
          state: { lessonType: 'vocabulary' },
        })
      }}
    >
      <div
        className={cn(
          'relative flex size-80 shrink-0 items-center justify-center rounded-full border-2 p-6',
          'border-lesson-icon-border',
        )}
      >
        <div
          className={cn(
            'flex size-full items-center justify-center overflow-hidden rounded-full',
            'bg-white',
          )}
        >
          <img className="w-half" src={img} alt="" />
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <p className="text-14 font-extrabold text-black opacity-30">
          <FormattedMessage id="Lesson" />
        </p>
        <p className="text-18 font-bold">{lessonText}</p>
      </div>
    </div>
  )
}

export function PracticeView() {
  // const intl = useIntl()
  //   const appModule = useAppModule()
  //   useMount(() => {
  //     appModule.practiceApi.getPracticePrograms()
  //   })
  return (
    <div className="w-full px-16 pb-100 pt-16 sm:mx-auto sm:w-800">
      <h1 className="text-center text-18 font-bold">
        <FormattedMessage id="practice.header" />
      </h1>
      <div className="mt-12 h-fit w-full rounded-12 bg-gray-light p-32 text-center text-gray7">
        <p className="text-18 font-bold">
          <FormattedMessage id="practice.wordStatEmpty" />
        </p>
        <p className="mt-12 text-14">
          <FormattedMessage id="practice.takeFirstLesson" />
        </p>
      </div>
      {/* <p className="mt-28 text-center text-48 font-black">312</p>
      <p className="text-center text-14 text-gray4">
        <FormattedMessage
          id="metrics.footerText"
          values={{
            boldText: (
              <span className="font-bold">
                {intl.formatMessage({ id: 'metrics.footerBoldSpan' })}
              </span>
            ),
          }}
        />
      </p>
      <div className="mt-24 flex h-132  w-full overflow-hidden rounded-16">
        {VOCAB_PROGRESS_BLOCKS.map((el, i) => (
          <ProgressElement
            key={`progress-el-${i}`}
            value={12}
            textColor={el.textColor}
            bgColor={el.bgColor}
          />
        ))}
      </div>
      <div className="mt-12 flex justify-center gap-16">
        {VOCAB_PROGRESS_TAGS.map((el, i) => (
          <ProgressElemTag
            key={`progress-el-tag-${i}`}
            bgColor={el.bgColor}
            id={el.id}
          />
        ))}
      </div> */}
      <h2 className="mt-24 text-22 font-bold">
        <FormattedMessage id="practice" />
      </h2>
      <div className="mt-8 flex flex-col gap-16">
        {MOCK_VOCAB_LESSONS.map((el, i) => (
          <VocabLesson
            img={el.img}
            lessonText={el.lessonText}
            lessonTag={el.lessonTag}
            key={`vocab-lesson-${i}`}
          />
        ))}
      </div>
    </div>
  )
}
