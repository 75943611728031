import { Outlet } from 'react-router-dom'
import { Toasts } from '../features/toasts/toasts.tsx'
import { cn } from '../shared/lib/utils.ts'
import { Navbar } from '../features/navbar/navbar.tsx'

export function Layout(props: {
  withMenu: boolean
  withNavbar: boolean
  fullWidth?: boolean
  scroll?: boolean
  white?: boolean
}) {
  return (
    <div
      className={cn(
        'w-full [scrollbar-width:thin]',
        props.white ? 'bg-white' : 'bg-light',
      )}
    >
      <div className="relative flex min-h-dvh">
        {props.withNavbar && <Navbar />}

        <Outlet />
      </div>
      <Toasts />
    </div>
  )
}
