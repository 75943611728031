import { StreamService } from './audio/streamService.ts'
import { EdenaiApi } from '../../shared/api/edenaiApi.ts'
import { Howl } from 'howler'
import { ErrorNotifier } from '../../shared/api/httpClient.ts'
import { howlerPlay } from '../../shared/lib/howlerUtils.ts'
import { clearTextForSpeech } from '../../shared/lib/utils.ts'

export class SpeechService {
  audio?: HTMLAudioElement
  inited = false
  constructor(
    public streamService: StreamService,
    private edenaiApi: EdenaiApi,
    private errorNotifier: ErrorNotifier,
  ) {}

  async init() {
    this.inited = true
    await this.streamService.getRecorder()
  }

  async start(recordingStatus: { recording: boolean }) {
    const recorder = await this.streamService.getRecorder()
    if (recordingStatus.recording) {
      recorder.start()
    }
  }

  async requestData() {
    const recorder = await this.streamService.getRecorder()
    return await recorder.requestData()
  }

  async stopRecording() {
    if (this.streamService.items) {
      const recorder = this.streamService.items.recorder
      return await recorder.stopRecord()
    } else {
      return undefined
    }
  }
  async stopAndAnalyze(chatId: number) {
    const blob = await this.stopRecording()
    if (!blob) {
      return ''
    }
    const result = await this.edenaiApi.speechToText(chatId, blob)
    return result.text == '.' ? '' : result.text
  }

  dispose() {
    this.inited = false
    this.streamService.stopTracks()
    this.audio = undefined
    if (this.currentSound) {
      this.currentSound.howl.stop()
      this.currentSound.howl.unload()
      this.currentSound = undefined
    }
  }

  async playText(
    messageId: number | undefined,
    chatId: number,
    text: string,
    progressCallback: (progress: number) => void,
  ) {
    progressCallback(0)
    if (this.currentSound && this.currentSound.messageId == messageId) {
      this.currentSound.howl.stop()
      this.currentSound.howl.seek(0)
      return howlerPlay(this.currentSound.howl, progressCallback)
    }

    const cleanText = clearTextForSpeech(text)
    const url = await this.edenaiApi.textToSpeech(chatId, cleanText)
    try {
      await this.play(messageId, url, progressCallback)
    } catch (e) {
      this.errorNotifier.onError(e)
    }
  }

  currentSound?: {
    howl: Howl
    messageId: number
  }

  isPlaying() {
    return this.currentSound && this.currentSound.howl.playing()
  }

  setPause(messageId: number, pause: boolean) {
    if (!this.currentSound || this.currentSound.messageId != messageId) {
      return
    }
    if (pause) {
      this.currentSound.howl.pause()
    } else if (
      !this.currentSound.howl.playing() &&
      this.currentSound.howl.seek() > 0
    ) {
      this.currentSound.howl.play()
    }
  }

  async play(
    messageId: number | undefined,
    src: string,
    progressCallback: (progress: number, duration: number) => void,
  ) {
    const howl = new Howl({
      src: [src],
      format: ['wav'],
      html5: true,
    })
    if (messageId) {
      this.stopCurrentSound()
      this.currentSound = { howl, messageId }
    }
    return howlerPlay(howl, progressCallback)
  }

  stopCurrentSound() {
    if (this.currentSound) {
      this.currentSound.howl.stop()
      this.currentSound.howl.unload()
    }
  }
}
